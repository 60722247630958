@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-y: hidden;
}

/* FONTS */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: Armin Grotesk;
  src: url('../Fonts/ArminGroteskNormal.otf') format('otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Armin Grotesk Black";
  src: url(../Fonts/ArminGroteskBlack.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Black Italic";
  src: url(../Fonts/ArminGroteskBlackItalic.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Italic";
  src: url(../Fonts/ArminGroteskItalic.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Normal";
  src: url(../Fonts/ArminGroteskNormal.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Normal Italic";
  src: url(../Fonts/ArminGroteskNormalItalic.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Regular";
  src: url(../Fonts/ArminGroteskRegular.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Semibold";
  src: url(../Fonts/ArminGroteskSemiBold.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Semibold Italic";
  src: url(../Fonts/ArminGroteskSemiBoldItalic.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Thin";
  src: url(../Fonts/ArminGroteskThin.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Thin Italic";
  src: url(../Fonts/ArminGroteskThinItalic.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Ultrabold";
  src: url(../Fonts/ArminGroteskUltraBold.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Ultrabold Italic";
  src: url(../Fonts/ArminGroteskUltraBoldItalic.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Ultralight";
  src: url(../Fonts/ArminGroteskUltraLight.otf) format("openType");
}

@font-face {
  font-family: "Armin Grotesk Ultralight Italic";
  src: url(../Fonts/ArminGroteskUltraLightItalic.otf) format("openType");
}

.header_Text {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #b5b5b5;
}

.header_Text_Selected {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #232323;
}

.header_Text_R {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #b5b5b5;
}

.header_Text_Selected_R {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #232323;
}

.content_Text_Title {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 102.996px;
  line-height: 80px;
  text-transform: uppercase;
  text-align: start;
}

.content_Text_Subtitle {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 34.3321px;
  text-transform: uppercase;
  text-align: start;
}

.footer_Text_Address {
  margin: 0;
  font-family: "Armin Grotesk Ultralight";
  font-size: 15px;
  line-height: 20px;
}

.footer_Text_Hermes {
  font-family: "Armin Grotesk Ultralight";
  font-size: 19px;
  line-height: 27px;
  text-transform: uppercase;
}

.text_Body {
  font-family: "Armin Grotesk Ultralight";
  font-weight: 350;
  font-size: 17px;
  line-height: 28px;
  text-align: start;
}

.text_Services {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 27px;
  text-transform: uppercase;
}

.text_Partners {
  font-family: "Armin Grotesk Ultralight";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
}

.text_know_more {
  font-family: "Armin Grotesk Ultralight";
  font-weight: 350;
  font-size: 15px;
  line-height: 5px;
  text-align: justify;
  text-decoration: underline;
  cursor: pointer;
}

.text_Menu {
  margin: 0;
  font-family: "Armin Grotesk Black";
  font-size: 20px;
  text-transform: uppercase;
  color: #b5b5b5;
}

.content_section {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 89.95px);
}

.main_input {
  width: 100%;
  padding: 10px 20px;
  background-color: #fafafa;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
  color: #232323;
}

.main_input::placeholder {
  color: #b5b5b5;
}

.main_input:focus {
  outline: none;
}

.main_textarea {
  width: 100%;
  background: #fafafa;
  border: 1px solid #b5b5b5;
  border-radius: 8px;
  resize: none;
  color: #232323;
  padding: 10px 20px;
  display: block;
}

.main_textarea::placeholder {
  color: #b5b5b5;
}

.main_textarea:focus {
  outline: none;
}

.main_button {
  padding: 10px 20px;
  border: 1px solid #4893ff;
  border-radius: 8px;
  color: #b5b5b5;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.main_button-depressed {
  padding: 10px 20px;
  color: #fafafa;
  border: 1px solid #4893ff;
  background-color: #4893ff;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.main_button:hover {
  padding: 10px 20px;
  color: #fafafa;
  border: 1px solid #4893ff;
  background-color: #4893ff;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.main_file_input--styled {
  display: block;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  background-color: #e7e7e7;
  color: #4893ff;
  padding: 10px 20px;
  cursor: pointer;
}

.a { 
  max-width: none;
}

.main_file_input--hidden {
  display: none;
}

/* SIMPLE GRID */
/* ==== GRID SYSTEM ==== */

.container {
  width: 90%;
}

/* .row {
  position: relative;
  width: 100%;
} */

.row [class^="col"] {
  float: left;
  min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.hidden-sm {
  display: none;
}

/* 540px */
@media only screen and (max-width: 33.75em) {
  .container {
    width: 100%;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 96%;
  }

  .content_section {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 89.95px);
  }

  .content_Text_Title {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 50px;
    line-height: 53px;
    text-transform: uppercase;
    text-align: start;
  }

  .content_Text_Subtitle {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: start;
  }

  /* .text_Body {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
  } */

  .text_Partners {
    font-family: "Armin Grotesk Ultralight";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 10px;
  }

  .text_Services {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 17px;
    text-transform: uppercase;
  }

  .text_know_more {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 15px;
    line-height: 10px;
    text-align: justify;
    text-decoration: underline;
    cursor: pointer;
  }

  .content_Text_Title-splash {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 35px;
    line-height: 35px;
    text-transform: uppercase;
    text-align: start;
  }
}

/* 540px */
@media only screen and (min-width: 33.75em) {
  .container {
    width: 100%;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 96%;
  }

  .content_section {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 89.95px);
  }

  .content_Text_Title {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 60px;
    line-height: 63px;
    text-transform: uppercase;
    text-align: start;
  }

  .content_Text_Subtitle {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: start;
  }

  /* .text_Body {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
  } */

  .text_Partners {
    font-family: "Armin Grotesk Ultralight";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
  }

  .text_Services {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    text-transform: uppercase;
  }

  .text_know_more {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 15px;
    line-height: 10px;
    text-align: justify;
    text-decoration: underline;
    cursor: pointer;
  }

  .content_Text_Title-splash {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 55px;
    line-height: 35px;
    text-transform: uppercase;
    text-align: start;
  }
}

/* 720px */
@media only screen and (min-width: 45em) {
  .container {
    width: 85%;
    max-width: 60rem;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }

  .content_section {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 89.95px);
  }

  .header_Text {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 14px;
    text-transform: uppercase;
  }

  .header_Text_Selected {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    color: #232323;
  }

  .content_Text_Title {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 70px;
    line-height: 70px;
    text-transform: uppercase;
    text-align: start;
  }

  .content_Text_Subtitle {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 25px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: start;
  }

  /* .text_Body {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 16px;
    line-height: 25px;
    text-align: start;
  } */

  .text_Partners {
    font-family: "Armin Grotesk Ultralight";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
  }

  .text_Services {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 21px;
    text-transform: uppercase;
  }

  .text_know_more {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 15px;
    line-height: 5px;
    text-align: justify;
    text-decoration: underline;
    cursor: pointer;
  }

  .content_Text_Title-splash {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 65px;
    line-height: 60px;
    text-transform: uppercase;
    text-align: start;
  }
}

/* 960px */
@media only screen and (min-width: 60em) {
  .container {
    width: 100%;
    max-width: 60rem;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 80%;
  }

  .content_section {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 89.95px);
  }

  .header_Text {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    text-transform: uppercase;
  }

  .header_Text_Selected {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    color: #232323;
  }

  .content_Text_Title {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 102.996px;
    line-height: 103px;
    text-transform: uppercase;
    text-align: start;
  }

  .content_Text_Subtitle {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 34.3321px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: start;
  }

  .text_Body {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 20px;
    line-height: 28px;
    text-align: start;
  }

  .text_Partners {
    font-family: "Armin Grotesk Ultralight";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
  }

  .text_Services {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 27px;
    text-transform: uppercase;
  }
  .text_know_more {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 18px;
    line-height: 10px;
    text-align: justify;
    text-decoration: underline;
    cursor: pointer;
  }

  .content_Text_Title-splash {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 75px;
    line-height: 75px;
    text-transform: uppercase;
    text-align: start;
  }
}

/* 1260px */
@media only screen and (min-width: 78.75em) {
  .container {
    width: 100%;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 96%;
  }

  .content_section {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 89.95px);
  }

  .header_Text {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    text-transform: uppercase;
  }

  .header_Text_Selected {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    color: #232323;
  }

  .content_Text_Title {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 102.996px;
    line-height: 103px;
    text-transform: uppercase;
    text-align: start;
  }

  .content_Text_Subtitle {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 34.3321px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: start;
  }

  .text_Body {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }

  .text_Services {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 27px;
    text-transform: uppercase;
  }

  .text__Partners {
    font-family: "Armin Grotesk";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
  }
  
  .text_know_more {
    font-family: "Armin Grotesk Ultralight";
    font-weight: 350;
    font-size: 18px;
    line-height: 10px;
    text-align: justify;
    text-decoration: underline;
    cursor: pointer;
  }

  .content_Text_Title-splash {
    margin: 0;
    font-family: "Armin Grotesk Black";
    font-size: 90px;
    line-height: 90px;
    text-transform: uppercase;
    text-align: start;
  }
}

/* COLORS */
.color_text_Content {
  color: #232323;
}

.color_text_Subdued {
  color: #b5b5b5;
}

.color_Text_Base_Backgorund {
  color: #fafafa;
}

.color_Text_Gradient {
  color: #4893ff;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* UI COMPONENTS */

.headerColor {
  width: 100%;
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(10px);
}

.footerColor {
  background: linear-gradient(
    90deg,
    rgba(65, 192, 240, 0.9) -45.79%,
    rgba(47, 82, 160, 0.9) 153.58%
  );
  backdrop-filter: blur(3px);
}

.menuAppearance {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(10px);
}

.showMore {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(10px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

h1 {
  position: relative;
  text-align: center;
  color: #353535;
  font-size: 50px;
  font-family: "Cormorant Garamond", serif;
}

p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 18px;
  color: #676767;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}

/* 3 */
.btn-3 {
  background: rgb(0,172,238);
background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   background: rgba(2,126,251,1);
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover{
   background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover{
   color: rgba(2,126,251,1);
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
   background: rgba(2,126,251,1);
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}

/* 4 */
.btn-4 {
  background-color: #4dccc6;
  background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-4:hover{
  background-color: #89d8d3;
background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.btn-4 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:before,
.btn-4:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  transition: all 0.3s ease;
}
.btn-4:before {
  height: 0%;
  width: .1px;
}
.btn-4:after {
  width: 0%;
  height: .1px;
}
.btn-4:hover:before {
  height: 100%;
}
.btn-4:hover:after {
  width: 100%;
}
.btn-4 span:before,
.btn-4 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.9),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.9),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  transition: all 0.3s ease;
}
.btn-4 span:before {
  width: .1px;
  height: 0%;
}
.btn-4 span:after {
  width: 0%;
  height: .1px;
}
.btn-4 span:hover:before {
  height: 100%;
}
.btn-4 span:hover:after {
  width: 100%;
}

/* 5 */
.btn-5 {
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
  background: rgb(255,27,0);
  background: linear-gradient(0deg, rgba(255,27,0,1) 0%, rgba(251,75,2,1) 100%);
}
.btn-5:hover {
  color: #f0094a;
  background: transparent;
   box-shadow:none;
}
.btn-5:before,
.btn-5:after{
  content:'';
  position:absolute;
  top:0;
  right:0;
  height:2px;
  width:0;
  background: #f0094a;
  box-shadow:
   -1px -1px 5px 0px #fff,
   7px 7px 20px 0px #0003,
   4px 4px 5px 0px #0002;
  transition:400ms ease all;
}
.btn-5:after{
  right:inherit;
  top:inherit;
  left:0;
  bottom:0;
}
.btn-5:hover:before,
.btn-5:hover:after{
  width:100%;
  transition:800ms ease all;
}


/* 6 */
.btn-6 {
  background: rgb(247,150,192);
background: radial-gradient(circle, rgba(247,150,192,1) 0%, rgba(118,174,241,1) 100%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
 box-shadow:
   -1px -1px 20px 0px rgba(255,255,255,1),
   -4px -4px 5px 0px rgba(255,255,255,1),
   7px 7px 20px 0px rgba(0,0,0,.4),
   4px 4px 5px 0px rgba(0,0,0,.3);
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover{
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  box-shadow:
   -1px -1px 20px 0px rgba(255,255,255,1),
   -4px -4px 5px 0px rgba(255,255,255,1),
   7px 7px 20px 0px rgba(0,0,0,.4),
   4px 4px 5px 0px rgba(0,0,0,.3);
}
.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}
.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}
.btn-6 span:hover:before {
  width: 100%;
}
.btn-6 span:hover:after {
  width: 100%;
}

/* 7 */
.btn-7 {
background: linear-gradient(0deg, rgba(255,151,0,1) 0%, rgba(251,75,2,1) 100%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-7 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-7:before,
.btn-7:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: rgba(251,75,2,1);
  box-shadow:
   -7px -7px 20px 0px rgba(255,255,255,.9),
   -4px -4px 5px 0px rgba(255,255,255,.9),
   7px 7px 20px 0px rgba(0,0,0,.2),
   4px 4px 5px 0px rgba(0,0,0,.3);
  transition: all 0.3s ease;
}
.btn-7:before{
   height: 0%;
   width: 2px;
}
.btn-7:after {
  width: 0%;
  height: 2px;
}
.btn-7:hover{
  color: rgba(251,75,2,1);
  background: transparent;
}
.btn-7:hover:before {
  height: 100%;
}
.btn-7:hover:after {
  width: 100%;
}
.btn-7 span:before,
.btn-7 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: rgba(251,75,2,1);
  box-shadow:
   -7px -7px 20px 0px rgba(255,255,255,.9),
   -4px -4px 5px 0px rgba(255,255,255,.9),
   7px 7px 20px 0px rgba(0,0,0,.2),
   4px 4px 5px 0px rgba(0,0,0,.3);
  transition: all 0.3s ease;
}
.btn-7 span:before {
  width: 2px;
  height: 0%;
}
.btn-7 span:after {
  height: 2px;
  width: 0%;
}
.btn-7 span:hover:before {
  height: 100%;
}
.btn-7 span:hover:after {
  width: 100%;
}

/* 8 */
.btn-8 {
  background-color: #f0ecfc;
background-image: linear-gradient(315deg, #f0ecfc 0%, #c797eb 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-8 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-8:before,
.btn-8:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.btn-8:before{
   height: 0%;
   width: 2px;
}
.btn-8:after {
  width: 0%;
  height: 2px;
}
.btn-8:hover:before {
  height: 100%;
}
.btn-8:hover:after {
  width: 100%;
}
.btn-8:hover{
  background: transparent;
}
.btn-8 span:hover{
  color: #c797eb;
}
.btn-8 span:before,
.btn-8 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #c797eb;
  transition: all 0.3s ease;
}
.btn-8 span:before {
  width: 2px;
  height: 0%;
}
.btn-8 span:after {
  height: 2px;
  width: 0%;
}
.btn-8 span:hover:before {
  height: 100%;
}
.btn-8 span:hover:after {
  width: 100%;
}
  

/* 9 */
.btn-9 {
  border: none;
  transition: all 0.3s ease;
}
.btn-9:after {
  position: absolute;
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1fd1f9;
  background-image: linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%);
  transition: all 0.3s ease;
}
.btn-9:hover {
  background: transparent;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  color: #fff;
}
.btn-9:hover:after {
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}




