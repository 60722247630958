.loop-slider .inner {
  display: flex;
  width: fit-content;
  animation-name: loop;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: var(--direction);
  animation-duration: var(--duration);
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.2rem;
  color: #FAFAFA;
  background: linear-gradient(90deg, #4BB0FF -45.79%, #4893FF 153.58%);
  border-radius: 16px;
  font-family: 'Roboto Mono', monospace;
  padding: 1rem 1rem 1.4rem 1rem;
  margin-right: 1rem;
  min-width: 300px;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
}

.tag span {
  font-size: 1.2rem;
  color: #64748b;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}